import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import CollectionCard from '../ListingPage/CollectionCard'
import classNames from 'classnames'

function ProductCarousel({
  items,
  settings,
  quickViewModalData,
  quickViewButtonStyles,
}) {
  return (
    <Slider
      {...settings}
      className={
        'cardSlider collectionCard_list row contentWrapper_large collectionCard_slider'
      }
    >
      {items.map((item) => (
        <CollectionCard
          buttonStyles={quickViewButtonStyles}
          id={item.product_id}
          image={item.product_image_url}
          isLoading={false}
          key={item.product_id}
          name={item.product_name}
          oldPrice={item.product_old_price}
          onSale={item.product_on_sale}
          price={item.product_price}
          quickViewModalData={quickViewModalData}
          url={item.product_url}
        />
      ))}
    </Slider>
  )
}

ProductCarousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      product_id: PropTypes.number.isRequired,
      product_image_url: PropTypes.string.isRequired,
      product_name: PropTypes.string.isRequired,
      product_old_price: PropTypes.number,
      product_on_sale: PropTypes.bool,
      product_price: PropTypes.string.isRequired,
      product_url: PropTypes.string,
    }),
  ).isRequired,
  quickViewButtonStyles: PropTypes.object.isRequired,
  quickViewModalData: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
}

function CarouselSectionTitle({ title }) {
  return (
    <div className="sectionTitle--centered">
      <div className="sectionTitle--centered_container container">
        <div className="sectionTitle--centered_contentWrapper contentWrapper_small">
          <h3 className="sectionTitle--centered_title">{title}</h3>
        </div>
      </div>
    </div>
  )
}

CarouselSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

const Arrow = ({
  className,
  onClick,
  direction,
  carouselControlsButtonStyles,
}) => (
  <button
    className={className}
    onClick={onClick}
    style={{ ...carouselControlsButtonStyles }}
  >
    {direction === 'prev' ? 'Previous' : 'Next'}
  </button>
)

Arrow.propTypes = {
  carouselControlsButtonStyles: PropTypes.object,
  className: PropTypes.string,
  direction: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default function CarouselSection({ section = [], quickViewModalData }) {
  const { carousel_items = [] } = section
  const { is_gray } = section.config

  const quickViewButtonStyles = {
    '--button-quick-view-background': section.button_quick_view_background,
    '--button-quick-view-background-hover':
      section.button_quick_view_background_hover,
    '--button-quick-view-color': section.button_quick_view_color,
    '--button-quick-view-color-hover': section.button_quick_view_color_hover,
  }

  const carouselControlsButtonStyles = {
    '--button-carousel-controls-background':
      section.button_carousel_controls_background,
    '--button-carousel-controls-background-hover':
      section.button_carousel_controls_background_hover,
    '--button-carousel-controls-color': section.button_carousel_controls_color,
    '--button-carousel-controls-color-hover':
      section.button_carousel_controls_color_hover,
  }

  const sectionClasses = classNames({
    'section--grey': is_gray,
  })

  const carouselSettings = {
    dots: false,
    infinite: false,
    nextArrow: (
      <Arrow
        carouselControlsButtonStyles={carouselControlsButtonStyles}
        direction="next"
      />
    ),
    prevArrow: (
      <Arrow
        carouselControlsButtonStyles={carouselControlsButtonStyles}
        direction="prev"
      />
    ),
    responsive: [
      {
        breakpoint: 1400,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: 767,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 576,
        settings: { slidesToShow: 2 },
      },
    ],
    slider_name: 'collectionCard_slider',
    slidesToScroll: 1,
    slidesToShow: 5,
    speed: 500,
  }

  if (!carousel_items || carousel_items.length === 0) {
    return null
  }

  return (
    <section className={sectionClasses} key={section.node_id}>
      {section.title.trim() !== '' && (
        <CarouselSectionTitle title={section.title} />
      )}
      <div className="cardSlider_container container">
        <ProductCarousel
          items={carousel_items}
          quickViewButtonStyles={quickViewButtonStyles}
          quickViewModalData={quickViewModalData}
          settings={carouselSettings}
        />
      </div>
    </section>
  )
}

CarouselSection.propTypes = {
  quickViewModalData: PropTypes.object.isRequired,
  section: PropTypes.shape({
    button_carousel_controls_background: PropTypes.string,
    button_carousel_controls_background_hover: PropTypes.string,
    button_carousel_controls_color: PropTypes.string,
    button_carousel_controls_color_hover: PropTypes.string,
    button_quick_view_background: PropTypes.string,
    button_quick_view_background_hover: PropTypes.string,
    button_quick_view_color: PropTypes.string,
    button_quick_view_color_hover: PropTypes.string,
    carousel_items: PropTypes.arrayOf(
      PropTypes.shape({
        product_id: PropTypes.number.isRequired,
        product_image_url: PropTypes.string.isRequired,
        product_name: PropTypes.string.isRequired,
        product_old_price: PropTypes.number,
        product_on_sale: PropTypes.bool,
        product_price: PropTypes.string.isRequired,
        product_url: PropTypes.string,
      }),
    ),
    config: PropTypes.shape({
      is_gray: PropTypes.bool.isRequired,
    }).isRequired,
    node_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}
