import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { MainModal } from '@tc-packages/tc-common'
import { renderStars } from './utils'
import debounce from 'lodash/debounce'

const TCPaginationReviewItem = ({ item, translations }) => {
  const [clamped, setClamped] = useState(true)
  const [showButton, setShowButton] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const containerRef = useRef(null)

  const handleClick = useCallback(
    () => setClamped((prevClamped) => !prevClamped),
    [],
  )
  const handleImageClick = useCallback(() => setIsModalOpen(true), [])
  const handleCloseModal = useCallback(() => setIsModalOpen(false), [])

  const checkButtonAvailability = useCallback(() => {
    if (containerRef.current) {
      const hadClampClass = containerRef.current.classList.contains(
        'reviewComponent_text--clamped',
      )
      if (!hadClampClass)
        containerRef.current.classList.add('reviewComponent_text--clamped')
      setShowButton(
        containerRef.current.clientHeight < containerRef.current.scrollHeight,
      )
      if (!hadClampClass)
        containerRef.current.classList.remove('reviewComponent_text--clamped')
    }
  }, [])

  useEffect(() => {
    const debouncedCheck = debounce(checkButtonAvailability, 50)
    checkButtonAvailability()
    window.addEventListener('resize', debouncedCheck)
    return () => window.removeEventListener('resize', debouncedCheck)
  }, [checkButtonAvailability])

  const modalBody = (
    <div>
      <h2 className="sr-only sr-only-focusable" id="modal-title">
        Product review details
      </h2>
      <div className="modal-review-content" id="modal-description">
        <div className="modal-review-image-wrapper">
          {item.image_large && (
            <img
              alt={item.title}
              className="modal-review-image"
              src={item.image_large}
            />
          )}
        </div>
        <div className="modal-review-info">
          <div className="modal-review-info-row">
            <div className="product-review-rating">
              {renderStars(item.rating)}
            </div>
            <div className="reviewComponent_nameDate">
              {item.show_identifier && <span>{item.name} • </span>}
              <span>{new Date(item.created_at).toLocaleDateString()}</span>
            </div>
          </div>
          {item.title && (
            <h3 className="reviewComponent_title">{item.title}</h3>
          )}
          <p className="reviewComponent_text">{item.review}</p>
        </div>
      </div>
    </div>
  )

  return (
    <div className="reviewComponent_item">
      <div className="reviewComponent_itemHeadline">
        <div className="product-review-rating">{renderStars(item.rating)}</div>
        <div className="reviewComponent_nameDate">
          {item.show_identifier && <span>{item.name} • </span>}
          <span>{new Date(item.created_at).toLocaleDateString()}</span>
        </div>
      </div>
      <div className="mb-md-5">
        {item.title && <h4 className="reviewComponent_title">{item.title}</h4>}
        <p
          className={`${clamped ? 'reviewComponent_text--clamped' : 'reviewComponent_text'}`}
          ref={containerRef}
        >
          {item.review}
        </p>
        {showButton && (
          <span
            className={`reviewComponent_showMore ${clamped ? 'readMoreLink--down' : 'readMoreLink--up'}`}
            onClick={handleClick}
          >
            {clamped ? translations.readMore : translations.readLess}
          </span>
        )}
      </div>
      {item.image_large && (
        <img
          alt={item.title}
          className="reviewComponent_img"
          onClick={handleImageClick}
          src={item.image_large}
        />
      )}
      <MainModal
        handleClose={handleCloseModal}
        isModalOpen={isModalOpen}
        modalAttributes={{
          'aria-describedby': 'modal-description',
          'aria-labelledby': 'modal-title',
          'aria-modal': 'true',
          role: 'dialog',
        }}
        modalBody={modalBody}
      />
    </div>
  )
}

TCPaginationReviewItem.propTypes = {
  item: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image_large: PropTypes.string,
    image_thumb: PropTypes.string,
    name: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    review: PropTypes.string.isRequired,
    show_identifier: PropTypes.bool.isRequired,
    title: PropTypes.string,
  }).isRequired,
  translations: PropTypes.shape({
    readMore: PropTypes.string.isRequired,
    readLess: PropTypes.string.isRequired,
  }).isRequired,
}

export default TCPaginationReviewItem
